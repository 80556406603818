<template>
  <ModalMain @close="close">
    <div class="px-4 md:px-7 pt-2 w-full">
      <article class="animate-fade-in">
        <div class="text-center block">
          <h1 class="text-2xl tracking-wide font-semibold text-center animate-slide-down-fade-in01s">Add {{ typeLabel }} Address</h1>
          <div v-if="!complete" class="text-slate-100 mt-3 leading-tight animate-slide-down-fade-in04s text-center">
            <div v-if="addAliasStep === 1">
              <p class="mb-4">When you add an {{ typeLabel }} address to your account and you'll be able to <template v-if="addAliasType === 'EthereumAddress'">take part in our on-chain competitions,</template>make deposits directly and withdraw your winnings!</p>
              <p class="mb-4">To continue, you'll need to connect your wallet.</p>
              <ButtonButton
                size="sm"
                @click.prevent.stop="showConnect()"
              >
                Connect Wallet
              </ButtonButton>
            </div>
            <div v-else-if="addAliasStep === 2">
              <p v-if="wallet?.address" class="mb-5">Your currently selected {{ typeLabel }} address is below.</p>
              <p v-if="wallet?.address" class="mb-5 text-blue-500 text-xs font-bold">{{ wallet.address }}</p>
              <p class="mb-5">To complete the process of adding an address you'll need to sign a message using the above wallet, this doesn't cost anything and does not expose any private information.</p>
              <p class="mb-5">Once completed you'll be able to sign in with your either email address or your {{ typeLabel }} wallet.</p>
              <ButtonButton
                type="button"
                class="w-full"
                size="sm"
                :disabled="isLoading || wallet?.address === dupeAddress"
                :loading="isLoading"
                @click.prevent.stop="sign"
              >
                <Spinner v-if="isLoading"/>
                <span v-else class="block py-[2px]">
                  Continue
                </span>
              </ButtonButton>
              <div v-if="error" class="text-red text-center text-sm mt-4">{{ error }}</div>
            </div>
          </div>
          <div v-else class="text-slate-100 mt-3 leading-tight animate-slide-down-fade-in04s text-center">
            <p class="mb-4">Your new account has been connected</p>
            <!-- <p class="mb-4">Please check your email for the link to complete this process.</p> -->
            <ButtonButton size="sm" @click.prevent.stop="close">
              Close
            </ButtonButton>
          </div>
        </div>
      </article>
    </div>
  </ModalMain>
</template>

<script>
import { mapWritableState, mapActions } from 'pinia';
import { useAuthStore } from '@/store/auth';
import { useUserStore } from '@/store/user';
import { useUiStore } from '@/store/ui';
import { walletModalModeTypes, walletModalSubModeTypes } from '@/types/Wallet';

export default defineComponent({
  name: 'ModalAccountAddAlias',
  data() {
    return {
      isLoading: false,
      error: null,
      complete: false,
      dupeAddress: null,
    };
  },
  computed: {
    ...mapWritableState(useAuthStore, ['wallet', 'walletConnected', 'attach',]),
    ...mapWritableState(useUiStore, ['showAddAliasModal', 'addAliasType', 'addAliasStep', 'addAliasDest', 'web3LoginProvider',]),
    typeLabel() {
      switch (this.addAliasType) {
        case 'EthereumAddress':
          return 'Ethereum';
        case 'SolanaAddress':
          return 'Solana';
        default:
          return 'Unknown';
      }
    },
    chainType() {
      switch (this.addAliasType) {
        case 'EthereumAddress':
          return 'Ethereum';
        case 'SolanaAddress':
          return 'Solana';
        default:
          return null;
      }
    },
  },
  unmounted() {
    if (!this.complete) {
      this.wallet = null;
      this.attach = false;
    }
  },
  methods: {
    ...mapActions(useUserStore, ['getUserData',]),
    ...mapActions(useUiStore, ['openConnectModal', 'openTxModal',]),
    ...mapActions(useAuthStore, ['attachWallet',]),
    close() {
      switch (this.addAliasDest) {
        case 'swap':
          this.showAddAliasModal = false;
          this.openTxModal(walletModalModeTypes[0], walletModalSubModeTypes[0]);
          break;

        default:
          this.showAddAliasModal = false;
          break;
      }
    },
    showConnect() {
      this.showAddAliasModal = false;
      this.openConnectModal({
        web3Only: true,
        noLogin: true,
        fromEmail: true,
        chainType: this.chainType,
        swap: this.addAliasDest === 'swap',
      });
    },
    async sign() {
      this.isLoading = true;
      this.attach = true;

      if (!this.wallet?.address) {
        await this.attachWallet(this.web3LoginProvider, true);
      }

      if (!this.wallet?.address) { return; }

      try {
        const { message, requestId, } = await this.$nuxt.$api('user/alias/generate-message', {
          method: 'POST',
          body: {
            type: this.addAliasType,
            value: this.wallet?.address,
          },
        }, 1);

        const signature = await this.wallet.sign(this.wallet?.address, message);

        await this.$nuxt.$api('user/alias/verify', {
          method: 'POST',
          body: {
            signature,
            requestId,
          },
        }, 1);

        await this.getUserData();
        this.walletConnected = true;
        this.complete = true;
        this.attach = false;
        this.dupeAddress = null;
      } catch (err) {
        this.error = err.response?._data?.message || err.message;

        if (this.error === 'Alias already exists') {
          this.error = 'This address is already connected to another account. Please select another.';
          this.dupeAddress = this.wallet?.address;
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
});
</script>
